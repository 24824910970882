var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"tableContent",staticClass:"table-content"},[_c('div',{staticClass:"table-operation"},[_c('a-button',{attrs:{"type":"primary","size":"large"},on:{"click":function($event){_vm.positionForm = {}
        _vm.positionTitle = '添加职位'
        _vm.positionVisible = true}}},[_vm._v(" 添加职位 ")])],1),_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.list,"rowKey":"id"},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return _c('span',{},[_c('a',{staticClass:"mr-5",on:{"click":function($event){return _vm.editPosition(record)}}},[_vm._v(" 修改 ")]),_c('a',{on:{"click":function($event){return _vm.deletePosition(record)}}},[_vm._v("删除")])])}}])}),_c('a-modal',{attrs:{"dialog-class":"modal-content","width":"600px","visible":_vm.positionVisible,"title":_vm.positionTitle},on:{"update:visible":function($event){_vm.positionVisible=$event},"cancel":function($event){_vm.positionVisible = false},"ok":_vm.positionFormConfirm}},[_c('a-form-model',{ref:"form",attrs:{"model":_vm.positionForm,"label-col":{ span: 6 },"wrapper-col":{ span: 14 }}},[_c('a-form-model-item',{attrs:{"label":"职位名称","prop":"name","rules":{
          message: '请输入职位名称',
          trigger: ['blur', 'change'],
          required: true,
        }}},[_c('a-input',{attrs:{"placeholder":"请输入职位名称"},model:{value:(_vm.positionForm.name),callback:function ($$v) {_vm.$set(_vm.positionForm, "name", $$v)},expression:"positionForm.name"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }