<template>
  <div class="table-content" ref="tableContent">
    <div class="table-operation">
      <a-button
        type="primary"
        size="large"
        @click="
          positionForm = {}
          positionTitle = '添加职位'
          positionVisible = true
        "
      >
        添加职位
      </a-button>
    </div>
    <a-table :columns="columns" :data-source="list" rowKey="id">
      <span slot="action" slot-scope="text, record">
        <a class="mr-5" @click="editPosition(record)"> 修改 </a>
        <a @click="deletePosition(record)">删除</a>
      </span>
    </a-table>
    <!-- 添加职位 -->
    <a-modal
      dialog-class="modal-content"
      width="600px"
      :visible.sync="positionVisible"
      :title="positionTitle"
      @cancel="positionVisible = false"
      @ok="positionFormConfirm"
    >
      <a-form-model
        ref="form"
        :model="positionForm"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
      >
        <a-form-model-item
          label="职位名称"
          prop="name"
          :rules="{
            message: '请输入职位名称',
            trigger: ['blur', 'change'],
            required: true,
          }"
        >
          <a-input v-model="positionForm.name" placeholder="请输入职位名称" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { deletePosition, getPositionList, savePosition } from '@/api/position'
import _ from 'lodash'

export default {
  name: 'Position',
  data() {
    return {
      list: [],
      positionVisible: false,
      positionTitle: '',
      positionForm: {},
      columns: [
        {
          title: 'id',
          dataIndex: 'id',
        },
        {
          title: '职位',
          dataIndex: 'name',
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.$store.dispatch('loading/startContentLoading')
      getPositionList().then((res) => {
        if (res.code === 0) {
          this.list = res.data
        }
        this.$store.dispatch('loading/endContentLoading')
      })
    },
    positionFormConfirm() {
      this.$refs.form
        .validate()
        .then(() => {
          this.$message.loading({ content: '保存中', key: 'position' })
          savePosition(this.positionForm).then((res) => {
            if (res.code === 0) {
              this.$message.success({
                content: '保存成功',
                key: 'position',
              })
              this.positionVisible = false
              this.getList()
            } else {
              this.$message.error({ content: '保存失败', key: 'position' })
            }
          })
        })
        .catch((err) => err)
    },
    deletePosition({ id }) {
      this.$confirm({
        title: '删除职位',
        content: '确定要删除该职位吗？',
        icon: () =>
          this.$createElement('a-icon', {
            props: { type: 'info-circle' },
            style: 'color:#e63e30',
          }),
        onOk: () => {
          this.$message.loading({ content: '删除中', key: 'position' })
          deletePosition({ id }).then((res) => {
            if (res.code === 0) {
              this.$message.success({
                content: '删除成功',
                key: 'position',
              })
              this.getList()
            } else {
              this.$message.error({ content: '保存失败', key: 'position' })
            }
          })
        },
      })
    },
    editPosition(record) {
      this.positionForm = _.cloneDeep(record)
      this.positionTitle = '修改职位'
      this.positionVisible = true
    },
  },
}
</script>

<style scoped lang="less"></style>
